const baseUrls = {
  localhost: {
    BASE_URL: "http://localhost:8000",
    API_URL: "http://localhost:8000",
  },
  dev: {
    BASE_URL: "http://localhost:8000",
    API_URL: "http://localhost:8000",
  },
  // arba: {
  //   BASE_URL: "https://sonictempserver.arba-dev.uk",
  //   API_URL: "https://sonictempserver.arba-dev.uk",
  // },
  arba: {
    BASE_URL: "",
    API_URL: "",
  },
  // sonic: {
  //   BASE_URL: "https://b6e3-5-64-235-164.ngrok-free.app/",
  //   API_URL: "https://b6e3-5-64-235-164.ngrok-free.app/",
  // },
  // sonic: {
  //   BASE_URL: "https://jq5hvt7s-8000.inc1.devtunnels.ms/",
  //   API_URL: "https://jq5hvt7s-8000.inc1.devtunnels.ms/",
  // },
  sonic: {
    BASE_URL: "https://api-dev.sonicdata.com",
    API_URL: "https://api-dev.sonicdata.com",
  },
};

export default baseUrls[
    ["localhost", "dev"].includes(process.env.REACT_APP_ENV)
    ? "dev"
    : process.env.REACT_APP_ENV == "production"
    ? "sonic"
    : "arba"
];

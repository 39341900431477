export const MonitorGroupsEnum = Object.freeze({
    AIM: "AIM",
    AFEM: "AFEM"
});

export const userRoles = {
    ADMIN: "Admin",
    PORTAL_USER: "PortalUser",
    PARTNER_ADMIN: "PartnerAdmin",
    PARTNER_USER: "PartnerUser",
    COMPANY_ADMIN: "CompanyAdmin",
    COMPANY_USER: "CompanyUser"
}

export const detectionOrigins = {
    SONICKEY: {
        name: "SONICKEY",
        shortName: "SK"
    },
    FINGERPRINT: {
        name: "FINGERPRINT",
        shortName: "SID"
    }
}

export const Status = [
    "Active",
    "Suspended",
]

export const AssociatedRoles = [
    "PortalUser",
    "PartnerAdmin",
    "PartnerUser",
    "CompanyAdmin",
    "CompanyUser"
]

export const AccountType = [
    "Partner",
    "Company",
]

export const UserType = [
    "Admin",
    "Standard",
]

export const Channel = [
    "ALL",
    "STREAMREADER",
    "PORTAL",
    "MOBILE",
]

export const CompanyType = [
    "Advertising",
    "Artist",
    "Artist Manager",
    "CMO (Collective Management Organisation)",
    "Composer",
    "Corporate",
    "Distributor",
    "DSP",
    "Film/TV",
    "Label",
    "Monitor",
    "Music Management Services",
    "PRO (Public Rights Organisation)",
    "Production Library",
    "Publisher",
    "Rights Holder",
    "Song Writer",
    "Sub Publisher",
]

export const PartnerTypes = [
    "CMO",
    "Distributor",
    "Monitoring Company",
    "Music Management Services",
    "PRO",
    "Production Library",
    "Publisher",
    "Sub Publisher",
];

export const RadioStatus = [
    "Listening",
    "Not Listening",
    "Error",
]

export const PlatformRelease = [
    "OSX",
    "Windows",
    "Linux",
]

export const Labels = [
    "1605",
    "1-2-3-4 Go! Records",
    "1017 Records",
    "12 Tónar",
    "12k",
    "13th Planet Records",
    "143 Records",
    "14th Floor Records",
    "16th Avenue Records",
    "19 Recordings",
    "1965 Records",
    "2 Tone Records",
    "20th Century Fox Records",
    "21st Circuitry",
    "24 Hour Service Station",
    "3 Beat Records",
    "300 Entertainment",
    "301Studios",
    "33rd Street Records",
    "3CG Records",
    "3D Vision Records",
    "4 Star Records",
    "415 Records",
    "430 West Records",
    "4AD",
    "4th & Broadway",
    "5 Minute Walk",
    "5 Rue Christine",
    "50 Records",
    "50 Skidillion Watts",
    "504 Records",
    "550 Music",
    "604 Records",
    "625 Thrashcore",
    "679 Artists",
    "75 Ark",
    "77 Records",
    "7th Magnitude",
    "88rising",
    "8bitpeoples",
    "960 Music Group",
    "99 Records",
    "A Cappella Records",
    "A Different Drum",
    "A Low Hum",
    "A-F Records",
    "A-Musik",
    "A&E Records",
    "A&M Records",
    "Abbey Records",
    "Abbott Records",
    "ABC Classics",
    "ABC Dunhill Records",
    "ABC Records",
    "ABC-Paramount Records",
    "Abco Records",
    "ABKCO Records",
    "Able Label",
    "Abnak Records",
    "Abner Records",
    "Absolutely Kosher Records",
    "Abstract Logix",
    "Abundant Life Ministries",
    "Accent Records",
    "Accent Records (US)",
    "Ace Fu Records",
    "Ace of Clubs Records",
    "Ace of Hearts Records",
    "Ace of Hearts Records (US)",
    "Ace Records (United Kingdom)",
    "Ace Records (United States)",
    "Ache Records",
    "Acid Jazz Records",
    "Aco Records",
    "Acorn Records",
    "Acoustic Disc",
    "Acte Préalable",
    "Ad Noiseam",
    "Adam VIII",
    "Adeline Records",
    "Adelphi Records",
    "Aditya Music",
    "Advent Records",
    "Aegean Records",
    "Aeronaut Records",
    "AFM Records",
    "AFO Records",
    "African Museum",
    "Aftermath Entertainment",
    "Aggro Berlin",
    "Agit-Prop Records",
    "Agitprop! Records",
    "Ahdasee Records",
    "AIP Records",
    "Airport Route Recordings",
    "Ajax Records",
    "Aladdin Records",
    "Alarma Records",
    "Albany Records",
    "Albert Productions",
    "Alchemy Records (Japan)",
    "Alchemy Records (U.S.)",
    "Alcopop! Records",
    "Alegre Records",
    "Alert Records",
    "Alfa Matrix",
    "Alfa Records",
    "Alias Records",
    "Alien8 Recordings",
    "Alive Records",
    "All Around the World",
    "All Ice Records",
    "All Platinum Records",
    "All Saints Records",
    "All Star Records",
    "All the Madmen Records",
    "Allalom Music",
    "Allido Records",
    "Alligator Records",
    "Almo Sounds",
    "Almost Gold Recordings",
    "Alpha Pup Records",
    "Alta Records",
    "Altarus Records",
    "Alternative Tentacles",
    "AM:PM Records",
    "Amalgam Digital",
    "Amarillo Records",
    "Amaru Entertainment",
    "Ambiances Magnétiques",
    "Ambitiouz Entertainment",
    "Ambush Reality Records",
    "American Gramaphone",
    "American King Music",
    "American Modern Recordings",
    "American Music Records",
    "American Record Company",
    "American Record Corporation",
    "American Recording Productions",
    "American Recordings (US)",
    "Amha Records",
    "Amiga",
    "Ammor Records",
    "Amos Records",
    "Ampex Records",
    "Amphetamine Reptile Records",
    "Amplexus",
    "Amusic",
    "Amy Records",
    "Analekta",
    "Anchor Records",
    "And/oar",
    "Andie Records",
    "Andmoresound",
    "Angel Air Records",
    "Angel Records",
    "Angelophone Records",
    "Angular Recording Corporation",
    "Anhrefn Records",
    "AnimeTrax",
    "Aniplex",
    "Anjunabeats",
    "Ankst",
    "Ankstmusik",
    "Anna Records",
    "Annette Records",
    "Another Century Records",
    "Anova Music",
    "Anthem Records",
    "ANTI-",
    "Anti-Creative Records",
    "Anticon Records",
    "Antilles Records",
    "Antone's Record Label",
    "Anxious Records",
    "Anyway Records",
    "Aozora Records",
    "Apegenine recordings",
    "Apex Records",
    "Aphonia Recordings",
    "Apollo Records (1921) (U.S.)",
    "Apollo Records (1928) (U.S.)",
    "Apollo Records (1944) (U.S.)",
    "Apollo Records (Belgium)",
    "Appian Publications & Recordings",
    "Applause Records",
    "Apple Records",
    "April/Schneeball",
    "Apt Records",
    "Aquarius Records (Canada)",
    "Aquarius Records (Croatia)",
    "Arabesque Records",
    "ARC Records",
    "Arch Hill Recordings",
    "Archenemy Record Company",
    "Archeophone Records",
    "Architecture Label",
    "Archive International Productions",
    "Arcola",
    "Ardent Records",
    "Arena Rock Recording Co.",
    "Aretino Records",
    "Argo Records (U.S.)",
    "Argo Records (UK)",
    "Arhoolie Records",
    "Ariola Records",
    "Arista Nashville",
    "Arista Records",
    "Aristocrat Records",
    "Ark 21 Records",
    "Armada Music",
    "Around Us Entertainment",
    "Artanis Entertainment Group",
    "ARTISTdirect",
    "Artistic Records",
    "Arto Records",
    "Artoffact Records",
    "Arts & Crafts",
    "Asbestos Records",
    "Aesop Label",
    "Ash International",
    "Asian Man Records",
    "Asphodel Records",
    "Asthmatic Kitty Records",
    "Astralwerks",
    "Astro Magnetics",
    "ASV Records",
    "Asylum Records",
    "Atavistic Records",
    "Atco Records",
    "ATIC Records",
    "Atlantic Jaxx",
    "Atlantic Records",
    "ATO Records",
    "Atoll Records",
    "ATP Recordings",
    "Attack Records (UK)",
    "Attic Records (Canada)",
    "Attitude Records",
    "Atypeek Music",
    "Au Go Go Records",
    "Audio Antihero",
    "Audio Dregs",
    "Audio Fidelity Records",
    "Audio Therapy",
    "Audiogram",
    "Audiophile Records",
    "Audition Records",
    "Aum Fidelity",
    "Authentic Records",
    "Autograph Records",
    "Autumn Records",
    "AVA Recordings",
    "Avang Music",
    "Avantgarde Music",
    "Avatar Records",
    "Avatar Records (Electronic)",
    "Avco Records",
    "Avex Trax",
    "AVI Records",
    "Aviance Records",
    "Aware Records",
    "Awareness Records",
    "Azteca Records",
    "Azuli Records",
    "B-Boy Records",
    "B-Unique Records",
    "B.O.S.S.",
    "Babel Label",
    "Baby Records (Italian label)",
    "Babygrande Records",
    "Back Beat Records",
    "Backporch Revolution",
    "Bad Boy Records",
    "Bad Taste",
    "Bad Taste Records",
    "Badorb.com",
    "Balkan Samba Records",
    "Balkanton",
    "Bally Records",
    "Banda Records",
    "Bang Records",
    "Bangor Records",
    "Banner Records",
    "Bar/None Records",
    "Baratos Afins",
    "Barclay Records",
    "Barely Breaking Even",
    "Barking Hoop Recordings",
    "Barking Pumpkin Records",
    "Barn Records",
    "Barnaby Records",
    "Barrelhouse Records",
    "Barsuk Records",
    "Basic Beat Recordings",
    "Basic Replay",
    "Basick Records",
    "Bassivity Music",
    "Baton Records",
    "Bayou Teche Brewery",
    "BBC Radio Collection",
    "BBC Records",
    "BBR Music Group",
    "BC Recordings",
    "BCore Disc",
    "Bear Family Records",
    "Bearsville Records",
    "Beat Factory",
    "Beatservice Records",
    "BEC Recordings",
    "Bedazzled Records",
    "Bedrock Records",
    "Bedroom Community",
    "Beggars Banquet Records",
    "Beka Records",
    "Bel-Tone Records",
    "Bell Records (1920s) (U.S.)",
    "Bell Records (1940) (U.S.)",
    "Bell Records (U.S. and U.K.)",
    "Bella Union",
    "Bellaccord",
    "Bellmark Records",
    "Below Par Records",
    "Beltona Records",
    "Beluga Heights Records",
    "Belvedere Records",
    "Benbecula Records",
    "Benson Records",
    "Berlee Records",
    "Berliner Gramophone",
    "Beserkley Records",
    "Bet-Car Records",
    "Beta-lactam Ring Records",
    "Bethlehem Records",
    "Better Looking Records",
    "Beverley's",
    "Beyond Records (Norway) (2011)",
    "Beyond Records (U.K. 1992)",
    "Beyond Therapy Records",
    "BI'N Music",
    "Bibletone Records",
    "Biddulph Recordings",
    "Bieler Bros. Records",
    "Big Bear Records",
    "Big Beat Records (Ace subsidiary)",
    "Big Beat Records (Atlantic subsidiary)",
    "Big Brother Recordings",
    "Big Cat Records",
    "Big Dada",
    "Big Hit Entertainment",
    "Big Hit Music",
    "Big Life",
    "Big Machine Records",
    "Big Neck Records",
    "Big Orange Clown Records",
    "Big Records",
    "Big Rig Records",
    "Big Scary Monsters Recording Company",
    "Big Time Records",
    "Big Top Records",
    "Big Tree Records",
    "Big Up Entertainment",
    "Big Vin Records",
    "Big Wheel Recreation",
    "Bigtop Records",
    "Biltmore Records",
    "Binge Records",
    "Bingola Records",
    "Biograph Records",
    "Birdman Records",
    "BIS Records",
    "Biscoito Fino",
    "bitbird",
    "Biv 10 Records",
    "Bizarre Records",
    "Black & White Records",
    "Black Acre",
    "Black and Greene Records",
    "Black Butter Records",
    "Black Hen Music",
    "Black Hole Recordings",
    "Black Jays",
    "Black Kapital Records",
    "Black Mark Production",
    "Black Market Activities",
    "Black Patti Records",
    "Black Pill Red Pill",
    "Black Saint/Soul Note",
    "Black Swan Records",
    "Black Swan Records (UK)",
    "Black Top Records",
    "Blackened Recordings",
    "Blackground Records",
    "Blackhouse Records",
    "Blackout Records",
    "Blacksmith Records",
    "Blast First",
    "BLIGHT. Records",
    "Block Entertainment",
    "Block Starz Music",
    "Blocks Recording Club",
    "Blonde Vinyl",
    "Blood and Fire",
    "Blood and Ink Records",
    "Bloodline Records",
    "Bloodshot Records",
    "Bloodwork Records",
    "Bloody Fist Records",
    "Blue Amberol Records",
    "Blue Beat Records",
    "Blue Cat Records",
    "Blue Dog Records",
    "Blue Goose Records",
    "Blue Horizon",
    "Blue Jordan Records",
    "Blue Lake Records",
    "Blue Note Records",
    "Blue Rock Records",
    "Blue Room Released",
    "Blue Swan Records",
    "Blue Thumb Records",
    "Bluebird Records",
    "Blues Beacon Records",
    "BlueSanct Records",
    "Bluesville Records",
    "Bluesway Records",
    "BME Recordings",
    "BMG",
    "BMG Heritage Records",
    "BMG Music Canada",
    "BMG Rights Management",
    "BNA Records",
    "Boardwalk Records",
    "Body Head Entertainment",
    "Bomp! Records",
    "Boner Records",
    "Bong Load Custom Records",
    "Bonnier Amigo Music Group",
    "Bonnier Gazell Music",
    "Bonsound Records",
    "Bonzai Records",
    "Boompa Records",
    "Boomtown Records",
    "Bop Cassettes",
    "Borderline Records",
    "Boy Better Know",
    "BPitch Control",
    "Bradley's Records",
    "Brainfeeder",
    "Brass Tacks Records",
    "Brassland Records",
    "Brave Wave Productions",
    "Breakbeat Kaos",
    "Breastfed Recordings",
    "Breezeway Records",
    "Brick Squad Monopoly",
    "Bridge 9 Records",
    "Bridge Records, Inc.",
    "Bright Antenna",
    "Brille Records",
    "Broadcast Twelve Records",
    "Broadmoor Records",
    "Broadway Records (1920s)",
    "Broadway Records (1947)",
    "Broadway Records (2012)",
    "Broken Records",
    "Broken Spoke Records",
    "Bronze Records",
    "Bros Records",
    "Brother Records",
    "Bruc Records",
    "Brunswick Records",
    "Brushfire Records",
    "Brutal Panda Records",
    "Brute/Beaute Records",
    "BSD Records",
    "BTNH Worldwide",
    "Buddah Records",
    "Buddyhead Records",
    "Buena Vista Music Group",
    "Bulb Records",
    "Bullet Records",
    "Bumstead Records",
    "Bungalo Records",
    "Bureau B",
    "Burger Records",
    "Burgundy Records",
    "Burning Heart Records",
    "Burnt Toast Vinyl",
    "Business Deal Records",
    "Busted Flat Records",
    "Busy Bee",
    "Butterfly Recordings",
    "BYG Actuel",
    "BYO Records",
    "C.I.A. Records",
    "C/Z Records",
    "C4 Records",
    "Cactus Jack Records",
    "Cadence Jazz Records",
    "Cadence Records",
    "Cadet Records",
    "Caedmon Audio",
    "Caff Records",
    "Calabash Music",
    "Caldo Verde Records",
    "CAM (Italy)",
    "CAM Entertainment (Japan)",
    "Camcor Records",
    "Cameo Records",
    "Cameo-Kid",
    "Cameo-Parkway Records",
    "Camino Records",
    "Camp Records",
    "Canaan Records",
    "Canadian-American Records",
    "Candid Records",
    "Candle Records",
    "Candlelight Records",
    "Candy Ass Records",
    "CandyRat Records",
    "Cantaloupe Music",
    "Cantora Records",
    "Capitol Hill Music",
    "Capitol Music Group",
    "Capitol Records",
    "Capricorn Records",
    "Captain Oi! Records",
    "Captains of Industry",
    "Caravan of Dreams Productions",
    "Carbon Copy Media",
    "Cardinal Records (1920s) (U.S.)",
    "Cardinal Records (1950s) (U.S.)",
    "Cardinal Records (1964) (Belgium)",
    "Cargo Music",
    "Cargo Records (Canada)",
    "Cargo Records (UK)",
    "Caribou Records",
    "Carish",
    "Carl Lindström Company",
    "Carnival Records",
    "Caroline Distribution",
    "Caroline Records (U.S. and U.K.)",
    "Carpark Records",
    "Carrillo Music",
    "Casablanca Records",
    "Cash Money Records",
    "Castle Communications",
    "Castle Records (Oregon)",
    "Catbird Records",
    "Catfish Records",
    "Cause 4 Concern Recordings",
    "Caustic Eye Productions",
    "Cavalier Records",
    "Cavetone Records",
    "Cavity Search Records",
    "CBC Records",
    "CBS Masterworks Records",
    "CBS Records",
    "Cedille Records",
    "Celestial Recordings",
    "Celluloid Records",
    "Celtic Music",
    "Celtophile Records",
    "Centaur Records",
    "Centricity Music",
    "Century Media Records",
    "Century Records",
    "Chainsaw Records",
    "Chalfont Records",
    "Chalice",
    "Challenge Records (1920s) (U.S.)",
    "Challenge Records (1950s) (U.S.)",
    "Challenge Records (1994) (Netherlands)",
    "Chameleon",
    "Chamillitary",
    "Chamillitary Entertainment",
    "Champion Records (Nashville, Tennessee)",
    "Champion Records (Richmond, Indiana)",
    "Champion Records (UK)",
    "Chance Records",
    "Chancellor Records",
    "Chandos Records",
    "Channel Classics Records",
    "Chapel Records",
    "Chappelle and Stinnette Records",
    "Chapter Music",
    "Charged Records",
    "Charisma Records",
    "Charly Records",
    "Chart Records",
    "Ché Trading",
    "Cheap Records",
    "Checker Records",
    "Cheeky Records",
    "Cheetah Records",
    "Chelsea Records",
    "Chemikal Underground",
    "Cherry Red",
    "CherryDisc Records",
    "Cherrytree Records",
    "Chesky Records",
    "Chess Records",
    "Chi Sound Records",
    "Chichūkai Label",
    "Chief Records",
    "Chimney Sweep Records",
    "China Record Corporation",
    "China Records",
    "Chiswick Records",
    "Chocolate City Music",
    "Chocolate Frog Records",
    "Chopper City Records",
    "Christian Faith Recordings",
    "Chrysalis Records",
    "Chunksaah Records",
    "CI Records",
    "Cinepoly Records",
    "Cinevox",
    "Cinnamon Toast Records",
    "Circle Records",
    "City Centre Offices",
    "City Slang Records",
    "Claddagh Records",
    "Classic Produktion Osnabrück",
    "Classics Club",
    "Claves Records",
    "Clay Records",
    "Clean Feed Records",
    "Clean-up Records",
    "Cleopatra Records",
    "Cleveland International Records",
    "Clickpop Records",
    "CLJ Records",
    "Cloud Recordings",
    "Clover Records",
    "CLS Records",
    "Clubbo Records",
    "CMC International",
    "Cobra Records",
    "Cock Rock Disco",
    "Coconut Records",
    "Cocoon Recordings",
    "Codiscos",
    "Coed Records",
    "Cold Chillin' Records",
    "Cold Chillin’ Records",
    "Cold Meat Industry",
    "Cold Spring",
    "Colgems Records",
    "Collectables Records",
    "Collector Records",
    "Collins Classics",
    "Colonial Records",
    "Colortone Records",
    "Colpix Records",
    "Columbia Graphophone Company",
    "Columbia Masterworks Records",
    "Columbia Music Entertainment",
    "Columbia Records",
    "Combat Records",
    "Come Organisation",
    "Comedy Central Records",
    "Command Performance Records",
    "Command Records",
    "Commodore Records",
    "Compadre Records",
    "Compass Records",
    "Composers Recordings, Inc.",
    "Compost Records",
    "Concert Artist Recordings",
    "Concord Music Group",
    "Concord Records",
    "Connoisseur Society",
    "Conqueror Records",
    "Conspiracy Music",
    "Constantinople Records",
    "Constellation Records (disambiguation)",
    "Contemode",
    "Contemporary Records",
    "Continental Records",
    "Cook Records",
    "Cooking Vinyl",
    "Cooltempo Records",
    "Copenhagen Records",
    "Copper Creek Records",
    "Coral Records",
    "Cordless Recordings",
    "Cornerstone RAS",
    "Corona Records",
    "Coronet Records",
    "Corporate Punishment Records",
    "Corpus Christi Records",
    "Corwood Industries",
    "Cotillion Records",
    "Country Turtle Records",
    "Cowboy Records",
    "CP Records",
    "Crammed Discs",
    "Crank! Records",
    "Crass Records",
    "Crave Records",
    "CRD Records",
    "Cream Records",
    "Creation Records",
    "Creative Vibes",
    "Credential Recordings",
    "Creeping Bent",
    "Creole Records",
    "Crescent Records",
    "Cricket Records",
    "Criminal IQ Records",
    "Crimson Records",
    "Croatia Records",
    "Cross Movement Records",
    "Crown Records",
    "Crunchy Frog Records",
    "Crustacean Records",
    "Cruz Records",
    "Crydamoure",
    "Crying Sun Records",
    "Crypt Records",
    "Cryptogramophone Records",
    "CTI Records",
    "Cub Records",
    "Cube Entertainment",
    "Cube Records",
    "Cuca Records",
    "Cult Records",
    "Culture Press",
    "Cumberland Records",
    "Cuneiform Records",
    "Curb Records",
    "Curling Legs",
    "Curtom Records",
    "Curves Music",
    "Custard Records",
    "Cutting Edge",
    "Cybele Records",
    "Cypress Records",
    "Czar Entertainment",
    "Czyz Records",
    "D Records",
    "D-Block Records",
    "D-Town Music Group, Inc.",
    "D.P.G. Recordz",
    "Da Lench Mob Records",
    "Daemon Records",
    "Daesung Entertainment",
    "Daffodil Records",
    "Dais Records",
    "Dallas Records",
    "Damaged Goods",
    "Dame Dash Music Group",
    "Damnably",
    "Damon Records",
    "Dana Records",
    "Dance Mania",
    "Dance to the Radio",
    "Dancing Cat Records",
    "Dancing Ferret",
    "Dandelion Records",
    "Danger Crue Records",
    "Dangerbird Records",
    "Dangerhouse Records",
    "Danse Macabre Records",
    "Daptone Records",
    "Dare to Care Records",
    "Dark Horse Records",
    "Darkest Labyrinth",
    "Darla Records",
    "Data Records",
    "Dawn Raid Entertainment",
    "Dawn Records",
    "Daylight Records",
    "DaySpring Records",
    "DB Records",
    "DC Flag Records",
    "DC-Jam Records",
    "DCide",
    "De Luxe Records",
    "De Werf",
    "De Wolfe Music",
    "Dead Oceans Records",
    "Dead Reckoning Records",
    "Deaf Records",
    "Death Row Records",
    "Deathlike Silence Productions",
    "Deathwish Inc.",
    "Debut Records",
    "Decaydance Records",
    "Decca Records",
    "Deceptive Records",
    "Decon Records",
    "Deconstruction Records",
    "Deep Elm Records",
    "Deep Shag Records",
    "Deep Soul Records",
    "Def American",
    "Def Jam Records",
    "Def Jam South",
    "Defected Records",
    "Defiance Records",
    "Definitive Jux",
    "Defstar Records",
    "Dekema Records",
    "Del-Fi Records",
    "Delerium Records",
    "Delicious Vinyl",
    "Delirium Records",
    "Delmark Records",
    "Delphine Records",
    "Deltasonic",
    "Deluxe Records",
    "Demon Records",
    "Dental Records",
    "Dented Records",
    "Dependent Music",
    "Dependent Records",
    "Deptford Fun City Records",
    "Deram Records",
    "Derby Records",
    "Derrty Entertainment",
    "Desert Storm",
    "DeSoto Records",
    "Desperation Records",
    "Desto Records",
    "Deutsche Grammophon",
    "Deviant Records",
    "Dew Process",
    "Dewdrops Records",
    "DF Music Organization",
    "DFA Records",
    "DFTBA Records",
    "DGC Records",
    "Dial Records (1946) (U.S.)",
    "Dial Records (1964) (U.S.)",
    "Dial Records (1998) (U.S.)",
    "Dial Records (1999) (Germany)",
    "Diamante Music Group",
    "Diamond Cut Productions",
    "Diamond Records",
    "Dice Records (1950/60's NY label)",
    "Dick Bros Record Company",
    "Diffusion Records",
    "Digital Hardcore Recordings",
    "DigSin",
    "Dim Mak Records",
    "Dimension Records",
    "Din Of Ecstasy Records",
    "Dine Alone Records",
    "Dino Entertainment",
    "Diplomat Records",
    "Diplomat Records (budget)",
    "Dirtnap Records",
    "Dirty Hit",
    "Dirty Records",
    "Dirtybird Records",
    "Disa Records",
    "Dischord Records",
    "Discipline Global Mobile",
    "Discodance",
    "Discos Fuentes",
    "Discos Taxco",
    "Discos y Cintas Denver",
    "Discovery Records",
    "DiscReet Records",
    "Diskos",
    "Diskoton",
    "Disneyland Records",
    "Disorient Records",
    "Displeased Records",
    "Disques Dreyfus",
    "Disques Victoire",
    "Disques Vogue",
    "Distiller Records",
    "Distinct'ive Breaks Records",
    "Distinct'ive Records",
    "Distort Entertainment",
    "Distribution Select",
    "Disturbing tha Peace",
    "Diva Records",
    "DIW Records",
    "Dixieland Jubilee Records",
    "DJM Records",
    "Do It Records",
    "Dream Catalogue",
    "Doctor Dream Records",
    "Document Records",
    "Dog Meat Records",
    "Doggystyle Records",
    "Dolores Recordings",
    "Dolphin Music",
    "Dolton Records",
    "Domino Recording Company (1993)",
    "Domino Records (1916–1917)",
    "Domino Records (1924–1934)",
    "Domino Records (1957–1961)",
    "Domo Records",
    "Don Giovanni Records",
    "Doomtree",
    "Dope House Records",
    "Doré Records",
    "Dot Dash Recordings",
    "Dot Records",
    "Double Zero Records",
    "Double-Time Records",
    "Doublemoon",
    "Dovecote Records",
    "Downtown Records",
    "Drag City",
    "Dragon Mob Records",
    "Dragon Records",
    "Dragon Street Records",
    "Drakkar Entertainment",
    "Drama Records",
    "Dramatico",
    "Draper Inc. Records",
    "Dream Chasers Records",
    "Dreamboat Records",
    "Dreamville Records",
    "DreamWorks Records",
    "Drive-Thru Records",
    "Driven Music Group",
    "Dro Records",
    "DROG Records",
    "DRT Entertainment",
    "Drumcode Records",
    "DSFA Records",
    "DSN Music",
    "Dualtone Records",
    "Duck Down Records",
    "Duck Records",
    "Ductape Records",
    "Duke Records",
    "Duke Street Records",
    "Duna Records",
    "Dunhill Records",
    "Dunk Yer Funk Records",
    "Dunwich Records",
    "Duophonic Records",
    "Durium Records",
    "DVS Records",
    "Dynamic",
    "Dynamica",
    "DynoVoice Records",
    "E.G. Records",
    "E1 Music",
    "Eagle Records",
    "Eagle Rock Entertainment",
    "Eagle Rock Records",
    "Earache Records",
    "Eardrum Records",
    "Early Records",
    "Earthly Delights",
    "Earwig Music Company",
    "East Meets West Music",
    "East Side Digital Records",
    "East West Records",
    "Eastern Conference Records",
    "Eastlawn Records",
    "EatUrMusic",
    "EBUL",
    "Echo Records",
    "Eclipse Records",
    "ECM",
    "Ecstatic Peace!",
    "Ecstatic Yod",
    "Ed Banger Records",
    "Edel Music",
    "Edel-Mega Records",
    "Edelweiss Emission",
    "Edison Bell",
    "Edison Records",
    "Edition Lilac",
    "Eenie Meenie Records",
    "Eerie Materials",
    "EFM Records",
    "EG Records",
    "EggHunt Records",
    "EGREM",
    "Eibon Records",
    "Ektaar Music Ltd",
    "El Cartel Records",
    "El Puerto Records",
    "Élan Recordings",
    "Electrecord",
    "Electric Honey",
    "Electrola",
    "Elefant Records",
    "Elefant Traks",
    "Elektra Records",
    "Element 9",
    "Elementree Records",
    "Elenco",
    "Eleven Seven Music",
    "Eleven: A Music Company",
    "eleveneleven",
    "Ellahy Amen Records",
    "Elypsia Records",
    "Em:t Records",
    "Emanem Records",
    "EmArcy Records",
    "Embassy Records",
    "Embryo Records",
    "Emerald Music",
    "Emerald Records (1966)",
    "Emerald Records (2000s)",
    "Emergency Broadcast System Records",
    "Emergency Records",
    "Emerson Records",
    "EMI",
    "EMI America Records",
    "EMI Christian Music Group",
    "EMI Classics",
    "EMI Hemisphere",
    "EMI Latin",
    "EMI Records",
    "EMI-Capitol Special Markets",
    "Emotive Sounds",
    "Emperor Jones",
    "Emperor Norton Records",
    "Empire Mates Entertainment",
    "empreintes DIGITALes",
    "End All Life Productions",
    "End Records",
    "Endearing Records",
    "Energy Rekords",
    "Enigma Records",
    "Enjoy Records",
    "Ensign Records",
    "Enterprise Records",
    "Entertainment One Music",
    "Enzyme Records",
    "Epic Records",
    "Epidemic Records",
    "Epitaph Records",
    "Equal Vision Records",
    "Equator Records (Canada)",
    "Equator Records (Kenya)",
    "Equity Music Group",
    "Era Records",
    "Erased Tapes Records",
    "Erato Records",
    "Eros Music",
    "Ersatz Audio",
    "Ersguterjunge",
    "Erstwhile Records",
    "Eskaton",
    "ESL Music",
    "ESP-Disk",
    "Esquimaux Management",
    "Esquire Records",
    "Esquire Records (UK)",
    "Essential Records (Christian)",
    "Essential Records (London)",
    "Essex Records",
    "Estonian Record Productions",
    "Estrus Records",
    "Esurient Communications",
    "Eternal Records",
    "Eulogy Recordings",
    "Eurodisc",
    "Europa",
    "Ever Reviled Records",
    "Everest Records",
    "Everloving Records",
    "Excel Records",
    "Excello Records",
    "Excelsior Recordings",
    "Exceptional Records",
    "Executive Music Group",
    "EXERCISE1 Records",
    "Exit Records",
    "Exodus Records",
    "Exploding in Sound",
    "Extasy Records",
    "Extensive Music",
    "Extreme Records",
    "Eye Q",
    "Eyeball Records",
    "F-Beat Records",
    "F-IRE Collective",
    "F.A.M ENT",
    "Facedown Records",
    "Factory Records",
    "Fade To Mind",
    "Fader Label",
    "Failsafe Records",
    "Falcon Records - subsidiary of Vee-Jay",
    "Falcon Records (Canadian label)",
    "Falcon Records (Texas)",
    "Fall of the West Records",
    "Falling A Records",
    "Family Tree Records",
    "Famous Records",
    "Fanfare Records",
    "Fania Records",
    "Fantastic Plastic Records",
    "Fantasy Records",
    "Fascination Records",
    "Fast Product",
    "Fat Cat Records",
    "Fat Possum Records",
    "Fat Wreck Chords",
    "Fatal Recordings",
    "Father/Daughter Records",
    "Faultline Records",
    "Favored Nations",
    "FAX +49-69/450464",
    "FCommunications",
    "Fearless Records",
    "Federal Records",
    "Fellside Records",
    "Felsted Records UK",
    "Fence Records",
    "Fenway Recordings",
    "Ferret Music",
    "Fervent Records",
    "Fervor Records",
    "Festival Distribution",
    "Festival Records",
    "Fever Records",
    "Fflach",
    "FFRR Records",
    "Fiction Records",
    "Fiddler Records",
    "Fidelity Records",
    "Fierce Angel",
    "Fierce Panda Records",
    "Fifth Colvmn Records",
    "Figure IV Entertainment",
    "Film Score Monthly",
    "FILMguerrero",
    "Filthy Note",
    "Filtro Musik",
    "Finger Lickin' Records",
    "Fingerprint Records",
    "Fire Records (Pakistan)",
    "Fire Records (U.S.)",
    "Fire Records (UK)",
    "Firebox Records",
    "First Avenue Records",
    "First Priority Music",
    "First Terrace Records",
    "Fitamin Un",
    "FiXT Music",
    "Flair Records",
    "Flameshovel Records",
    "Flamingo Recordings",
    "Flashover Recordings",
    "Flashpoint Music",
    "Flawless Records",
    "Fledg'ling Records",
    "Flemish Eye",
    "Flicker Records",
    "Flip Records (1950s)",
    "Flip Records (1994)",
    "Flipmode Entertainment",
    "Fly Records",
    "Flying Fish Records",
    "Flying Nun Records",
    "Flying Tart",
    "Flyright Records",
    "Flyte Tyme Records",
    "FM Records",
    "FMP (Free Music Production)",
    "FMR Records",
    "Focus Group Holdings Limited",
    "Fog City Records",
    "Fogarty's Cove Music",
    "Folk-Legacy Records",
    "Folkways Records",
    "Fonal Records",
    "Fondle 'Em Records",
    "Fonotipia Records",
    "Fonovisa Records",
    "Fontana Records",
    "Food Records",
    "Fool's Gold Records",
    "Fools of the World",
    "Forced Exposure",
    "Forces of Satan Records",
    "ForeFront Records",
    "Foreshadow",
    "Formation Records",
    "Fort Knocks Entertainment",
    "Fortissimo Records",
    "Fortuna Pop!",
    "Fortune Records",
    "Forward Motion Records",
    "Four Music",
    "Fourth And Broadway Records",
    "Fourth Dimension Records",
    "Fowl Records",
    "Fox Music",
    "Fractured Transmitter Recording Company",
    "Fraternity Records",
    "FRE Records",
    "Freak Recordings",
    "Freakdance Records",
    "Fred Records",
    "Free Dirt Records",
    "Free-Will",
    "Freebandz",
    "Freedom From",
    "Freedom Records",
    "Freeworld Entertainment",
    "Frenchkiss Records",
    "Fresh Records (UK)",
    "Fresh Records (US)",
    "Friday Lights Music",
    "Friendly Fire Recordings",
    "Friendly Folk Records",
    "Frills GmbH",
    "Fringe Product",
    "Frog Pad Records",
    "Frog Records",
    "Frontier Records",
    "Frontiers Records",
    "Frontline Records (1980s)",
    "FSUK Records",
    "Fuel 2000",
    "Fueled by Ramen",
    "Fueradeserie!",
    "Full Moon Productions",
    "Full Moon Records (US)",
    "Full Surface Records",
    "Full Time Hobby",
    "Fullsteam Records",
    "Funk Volume",
    "Funtrip Records",
    "Fury Records",
    "Fusion Records",
    "Future Classic",
    "Future Farmer Records",
    "Future Records",
    "Fysisk Format",
    "G-Funk Entertainment",
    "G-Series",
    "G-Unit Records",
    "G*59 Records",
    "G7 Welcoming Committee Records",
    "Galaxy Records",
    "Galaxy21 Music",
    "Galilee of the Nations",
    "Galileo Records",
    "Gallo Record Company",
    "Gamble Records",
    "Gan-Shin",
    "Gangsta Advisory Records",
    "Gearhead Records",
    "Gee Records",
    "Gee Street Records",
    "Geffen Records",
    "Gekko Records",
    "General Records",
    "Genjing Records",
    "Gennett Records",
    "Geoma Records",
    "Gern Blandsten Records",
    "Get Low Recordz",
    "Ghetto Ruff",
    "Ghost Box Music",
    "Ghostlight Records",
    "Ghostly International",
    "Giant Electric Pea",
    "Giant Records",
    "Giant Step",
    "Giantslayer Records",
    "Gifted Records",
    "Gigantic Music",
    "Giorno Poetry Systems",
    "Giza Studio",
    "Glacial Pace",
    "Glasgow Underground Recordings",
    "Glitch Mode Recordings",
    "Glitterbeat Records",
    "Glitterhouse Records",
    "Global Music Group",
    "Global Trance Network",
    "Glowworm Records",
    "Glurp",
    "GMA Records",
    "GMM Grammy",
    "GMT Records",
    "Gnomonsong",
    "GNP Crescendo Records",
    "Go-Feet Records",
    "Go-Kart Records",
    "Go-Kustom Rekords",
    "Go! Beat Records",
    "Godzilla Entertainment",
    "Gold Castle Records",
    "Gold Label Records",
    "Gold Mind Records",
    "Gold Mountain Records",
    "Gold Robot Records",
    "Gold Standard Laboratories",
    "Gold Star Records",
    "Gold Typhoon",
    "Golden Era Records",
    "Golden World Records",
    "Goldenrod Records",
    "Goldwax Records",
    "Gone Records",
    "Goner Records",
    "Good Entertainment",
    "Good Hands Records",
    "Good Looking Records",
    "GOOD Music",
    "Good Records",
    "Good Time Jazz Records",
    "Good to Die Records",
    "Good Vibrations",
    "Gooom Disques",
    "Gordy Records",
    "GospoCentric Records",
    "Gotee Records",
    "Gourd Music",
    "Grafton Entertainment",
    "Gramavision Records",
    "Gramm",
    "Gramophone Company",
    "Granary Music",
    "Grand Award Records",
    "Grand Central Records",
    "Grand Hustle Records",
    "Grand Production",
    "Grand Royal",
    "Grapefruit",
    "Grappa Music",
    "Grateful Dead Records",
    "Graveface Records",
    "Gravitas Recordings",
    "Gravity Records",
    "Great Big Mouth Records",
    "Greedhead Music",
    "Green Cookie Records",
    "Green Linnet Records",
    "Greenhouse Music",
    "Greensleeves Records",
    "Greentrax Recordings",
    "Gregmark Records",
    "Grenadine Records",
    "Grey Gull Records",
    "Greyday Productions",
    "Griffin Music",
    "Gringo Records",
    "Groove Records",
    "GRP Records",
    "GRRR Records",
    "Grunt Records",
    "GTO Records",
    "Guerilla Records",
    "Guided Missile",
    "Guitarmonk Records",
    "Gulcher Records",
    "GUN Records",
    "Gusto Records",
    "Gut Records",
    "Gutterth",
    "Guy Cloutier Communications",
    "GWR Records",
    "H&L Records",
    "Hachama",
    "Hades Records",
    "Hakatak International",
    "Half A Cow",
    "Hallucination Recordings",
    "Hamburg Records",
    "Hamilton Records",
    "Hammock Music",
    "Hand Drawn Dracula",
    "Handsome Boy Records",
    "Hangars Liquides",
    "Hangman Records",
    "Hanna Barbera Records",
    "Hannibal Records",
    "Hansa Records",
    "Hanson Records",
    "Häpna",
    "Happy Couples Never Last",
    "Happy Happy Birthday To Me Records",
    "Happy Tiger Records",
    "HappySad Records",
    "Hardwood Records",
    "Harlekijn",
    "Harmolodics",
    "Harmonia Mundi",
    "Harmony Records",
    "Harriet Records",
    "Harthouse",
    "Harvest Records",
    "Hatchet House",
    "Hathut Records",
    "Headhunter Records",
    "Hear Music",
    "Heart Warming",
    "Heartbeat Records",
    "Heartbeat Records (Bristol)",
    "Hearts of Space Records",
    "Heaven Music",
    "Heaven Records",
    "Heavenly Records",
    "Hed Artzi Music",
    "Hed Kandi",
    "Hefty Records",
    "Heiress Records",
    "Heist Or Hit Records",
    "Helicon Records",
    "Hell, etc.",
    "Hellcat Records",
    "Hellhound Records",
    "Hello CD of the Month Club",
    "Hello Cleveland!",
    "Hep-Me Records",
    "Her Royal Majesty's Records",
    "Herald AV Publications",
    "Herald Records",
    "Heritage Records",
    "Hermes Records",
    "Herwin Records",
    "Hevy Devy Records",
    "Hi Life Recordings",
    "Hi Or Hey Records",
    "Hi Records",
    "Hi-n-Dry",
    "Hib-Tone",
    "Hickory Records",
    "Hidden Beach Recordings",
    "Hieroglyphics Imperium Recordings",
    "High Dive Records",
    "High Street Records",
    "High Water Recording Company",
    "Higher Ground Records",
    "Higher Octave",
    "Higher State",
    "Highland Records",
    "HighTone Records",
    "Hiljaiset Levyt",
    "HIM International Music",
    "Hip Records",
    "Hip-O Records",
    "Hipposonic Studios",
    "His Master's Voice",
    "Historic Masters",
    "Historical Records",
    "Hit of the Week Records",
    "Hitz Committee",
    "Hobbledehoy Record Co.",
    "Holiday Inn Records",
    "Holiday Records",
    "Hollywood Records",
    "Holy Roar Records",
    "Home Sweet Home Records",
    "Homespun Records",
    "Homestead Records",
    "Homestead Records (1920s)",
    "Honest Don's Records",
    "Honest Jon's",
    "Hoo-Bangin' Records",
    "Hope Music Group",
    "Hope Recordings",
    "Hopeless Records",
    "HopeStreet Recordings",
    "Hor Music",
    "Horizon Records",
    "Horris Records",
    "Horror House Entertainment.",
    "Hosanna! Music",
    "Hospital Records",
    "HOSS Records",
    "Hot Pot Music",
    "Hot Wax Records",
    "How's That Music",
    "HQ Recordings",
    "Hugpatch Records",
    "Human Imprint",
    "Humble Beast",
    "Humming Bird Records",
    "Hungaroton",
    "Hush Records",
    "Hushush",
    "Hut Records",
    "Hwem",
    "Hybris",
    "Hydra Head Records",
    "Hyperdub",
    "Hyperion Records",
    "Hyperium Records",
    "Hypertek Digital",
    "Hypnos",
    "Hypnotize Minds",
    "I Scream Records",
    "I.R.S. Records",
    "IAMSOUND Records",
    "Ice Age Entertainment",
    "Ice Records",
    "Ici, d'ailleurs...",
    "Idea Records",
    "Ideal Records",
    "Idol Records",
    "Ignition Records",
    "III Records",
    "Ill Flava Records",
    "Ill Will Records",
    "Ill.Skillz Recordings",
    "Illegal Art",
    "Illegal Musik",
    "Illegal Records",
    "Illuminating Technologies",
    "Imagen Records",
    "Imaginary Records",
    "Immediate Records",
    "Immortal Records",
    "Impact (New Zealand)",
    "Impact Records",
    "Impact Records (California)",
    "Imperial Records (1900) (U.S.)",
    "Imperial Records (1920) (U.K.)",
    "Imperial Records (1947 and 2006 U.S.)",
    "Imprint Records",
    "Impulse! Records",
    "Imputor?",
    "In Records (Australia)",
    "In Records (United States)",
    "In the Red Records",
    "In-Fidelity Recordings",
    "Incentive Records",
    "INCredible",
    "Incus Records",
    "Indecision Records",
    "Independiente Records",
    "Indestructible Record Company",
    "Indi Script Records",
    "Indianola Records",
    "Indirecto Records",
    "Industrial Records",
    "Infectious Records",
    "Infinity Cat Recordings",
    "Infinity Recordings",
    "Infrared",
    "Initial Records",
    "Inner Ear",
    "Innerground Music",
    "Innocent Records",
    "Innova Recordings",
    "INO Records",
    "Inoxia Records",
    "Inpop Records",
    "Inside Recordings",
    "InsideOut Music",
    "InsideOut US",
    "Instant Karma",
    "Instinct Records",
    "Intec Digital",
    "Integrity Records",
    "Intense Records",
    "Interactive Jack Records",
    "International Artists",
    "International DeeJay Gigolo Records",
    "Internet Money",
    "Interphon Records",
    "Interscope Records",
    "Interscope-Geffen-A&M",
    "Intrada Records",
    "Invictus Records",
    "Invisible Agent",
    "Invisible Hands Music",
    "Invisible Records",
    "inVogue Records",
    "Iodine Recordings",
    "Iona Records",
    "Ipecac Recordings",
    "Ironworks",
    "Isadora Records",
    "Island Blue Records",
    "Island Masters",
    "Island Records",
    "Island Reggae Greats",
    "Italians Do It Better",
    "It’s A Wonderful World Music Group",
    "Ivy League Records",
    "Ivy Queen Musa Sound",
    "J & S Records",
    "J Records",
    "J Storm",
    "J.O.B. Records",
    "Jackson Records",
    "JAD Records",
    "Jade Tree Records",
    "Jagjaguwar",
    "Jahtari",
    "Jamaican Gold",
    "Jamie Records",
    "Janus Records",
    "Jaro Medien",
    "Jarrah Records",
    "Jarring Effects",
    "Jasmine Records",
    "Jaus Records",
    "Javotti Media",
    "JAXART Records (U.S.)",
    "Jay Boy",
    "Jay-Jay Records",
    "JayTee Records",
    "Jazz Man Records",
    "Jazz, Ltd.",
    "Jazzaway Records",
    "Jazzland Records (1960) (U.S.)",
    "Jazzland Records (1997) (Norway)",
    "Jazzology Records",
    "JDC Records",
    "JDub Records",
    "Jeepster Records",
    "Jehova-Nisi Producciones",
    "Jellyfish Entertainment",
    "JEMP Records",
    "Jerden Records",
    "Jericho Beach Music",
    "Jester Records",
    "Jesuit Music Ministry",
    "Jet Records",
    "Jet Set Records",
    "Jetydosa",
    "Jewel Records (Cincinnati, Ohio)",
    "Jewel Records (New York)",
    "Jewel Records (Shreveport, Louisiana)",
    "Jewel Runners",
    "Jiggiri Records",
    "Jim Henson Records",
    "Jimmy Franks Recording Company",
    "Jive Electro",
    "Jive Records",
    "JMT Records",
    "Joe & Joey Records",
    "Johann's Face Records",
    "Johnny & Associates",
    "Joilicious Records",
    "Jolly Roger Records",
    "Josie Records",
    "Joyful Noise Recordings",
    "JSP Records",
    "Juana Records",
    "Jubilee Records",
    "Judd Records",
    "Judgement Records",
    "Jugoton",
    "Juice Box Records",
    "Juke Box Records",
    "Jumbo Records",
    "Jump Records",
    "Jungle Records",
    "Junior Boy's Own",
    "Justablip Records",
    "JYP Entertainment",
    "K Records",
    "K-BAHN",
    "K-tel Records",
    "K&K Verlagsanstalt",
    "Kahvi",
    "Kaifa Records",
    "Kairos",
    "Kalakuta Republic",
    "Kalan Müzik",
    "Kama Sutra Records",
    "Kanine Records",
    "Kapow Records",
    "Kapp Records",
    "Karmageddon Media",
    "Kedar Records",
    "Keen Records",
    "Kelp Records",
    "Kemado Records",
    "Kemosabe Records",
    "Kennis Music",
    "Kent Records",
    "Key Sounds Label",
    "KFM Records",
    "Ki/oon Music",
    "Kickball Records",
    "Kicking Mule Records",
    "Kid Stuff Records",
    "Kiddyphone",
    "Kill Rock Stars",
    "Kimi Records",
    "Kindercore Records",
    "Kinetic Records",
    "King Records (Japan)",
    "King Records (USA)",
    "King Worldwide",
    "Kirtland Records",
    "Kitchen Motors",
    "Kitchenware Records",
    "Kitsuné Music",
    "Kitty Kitty Corporation",
    "Kitty Play Records",
    "Kitty-Yo",
    "KLF Communications",
    "KMB Jazz",
    "Knee Deep Records",
    "Knitting Factory Records",
    "Knockout Entertainment",
    "Koch Distribution",
    "Koch Entertainment",
    "Koch Entertainment Canada",
    "Koch International Records",
    "Koch Publishing",
    "Kokomo Records",
    "Kompakt",
    "Kon Live Distribution",
    "Konichiwa Records",
    "Kontor Records",
    "Konvict Muzik",
    "Koolarrow Records",
    "Korova",
    "Kranky",
    "Kross Over Entertainment",
    "Kscope",
    "Kung Fu Records",
    "Kvitnu",
    "La Tribu",
    "Label Fandango",
    "Labrador Records",
    "LaFace Records",
    "Laff Records",
    "Lakeshore Records",
    "Lakeside Records",
    "Lamon Records",
    "Lanor Records",
    "LaSalle Records",
    "Last Gang Records",
    "Latent Recordings",
    "Latino Buggerveil",
    "Laundry Label",
    "Laurie Records",
    "Lava Records",
    "Le Grand Magistery",
    "Leader Records (UK)",
    "Leader Records (US)",
    "Leaf Hound Records",
    "Leathür Records",
    "Leedon Records",
    "Leeds Talk-O-Phone",
    "Leesta Vall",
    "LeFrak-Moelis Records",
    "Legacy Recordings",
    "Legendary Music",
    "Legion Records",
    "Legit Ballin'",
    "Lench Mob Records",
    "Lengua Armada Discos",
    "Lens Records",
    "Les Disques du Crepuscule",
    "Level Plane",
    "Lex Records",
    "LHI Records",
    "Liberation Music",
    "Liberation Records",
    "Liberty & Lament",
    "Liberty Music Shop Records",
    "Liberty Records",
    "Library Records",
    "Licking Fingers",
    "Lifeforce Records",
    "Light Organ Records",
    "Light Records",
    "Lil' Chief Records",
    "Limb Music",
    "Limelight Records",
    "Limp Records",
    "Lincoln Records",
    "Lingasong Records",
    "Linn Records",
    "Linus Entertainment",
    "Lion Music",
    "Lioness Records",
    "Lionheart Music Group",
    "Liquor and Poker Music",
    "Listenable Records",
    "Lithium",
    "Little David Records",
    "Little Marvel",
    "Little Wonder Records",
    "Lizard",
    "Lizard King Records",
    "Lo Recordings",
    "Lo-Fidelity Records",
    "Load Records",
    "Loaded Records",
    "Lobster Records",
    "LOCA Records",
    "Local Action",
    "Locked On Records",
    "Locomotive Music",
    "Locust Music",
    "LOEN Entertainment",
    "Lofton Creek Records",
    "Logo Records",
    "Lojinx",
    "London Records",
    "London-Sire Records",
    "Lonely Astronaut Records",
    "Lonesome Day Records",
    "Longhorn Records",
    "Longines Symphonette Society",
    "Loningisa",
    "Lookout! Records",
    "Loöq Records",
    "Loose Music",
    "Loose Records & Music",
    "Losen Records",
    "Lost & Lonesome Recording Co.",
    "Lost Highway Records",
    "Lost Language",
    "Lotuspike",
    "Loud Records",
    "Love Is My Velocity",
    "Love Minus Zero Recordings",
    "Love Police Records",
    "Love Records",
    "Love Renaissance",
    "LoveCat Music",
    "Lovely Music",
    "Loveway Records",
    "Low Life Records",
    "Low Transit Industries",
    "Lowercase People Records",
    "LRRC (Luddite Rural Recording Cooperative)",
    "LTM Recordings",
    "Lu Pine Records",
    "Luaka Bop",
    "Lucid Records",
    "Lucidsamples",
    "Lucky Eleven Records",
    "Lucky Four Records",
    "Lucky Records",
    "Lujo Records",
    "Luke Records",
    "Lumiere Records",
    "Lynx Entertainment",
    "Lyric Records (Germany)",
    "Lyric Records (US)",
    "Lyric Street Records",
    "Lyrita Recorded Edition",
    "M.A.C.E. Music",
    "M&G Records",
    "M3 Records",
    "Machete Music",
    "Machine Shop Recordings",
    "Machinery Records",
    "Mad Decent",
    "MAD Dragon Records",
    "Madhouse Records",
    "Madison Gate Records",
    "Madison Records",
    "Magic Bullet Records",
    "Magic Circle Music",
    "Magna Carta Records",
    "Magnanimous Records",
    "Magnatune",
    "Magnet Records",
    "Magpie Records",
    "Mailboat Records",
    "Mainstream Records",
    "Mainya Music",
    "Maison de Soul",
    "Majestic Record Corporation",
    "Majestic Records",
    "Majik Ninja Entertainment",
    "Major League Productions (MLP)",
    "Major Minor Records",
    "Mala Records",
    "Malaco Records",
    "Maltine Records",
    "MAM Records",
    "Mamlish Records",
    "Mammoth Records",
    "Man's Ruin Records",
    "Mango Records",
    "Manhattan Records",
    "Manifesto Records",
    "Manifold Records",
    "Manimal Vinyl",
    "Manna Music Inc",
    "Manor Records",
    "Manticore Records",
    "Mantra Recordings",
    "Manyc Records",
    "MapleMusic Recordings",
    "Maranatha! Music",
    "Marian Records",
    "Mariann Grammofon AB",
    "Marina Records",
    "Marine Parade Records",
    "Market Square Records",
    "Marlin Records",
    "Marmalade Records",
    "Marrakesh Records",
    "Marriage Records",
    "Marsalis Music",
    "Mas Flow Inc",
    "Mascot Records",
    "Mass Appeal Records",
    "mass mvmnt",
    "Massacre Records",
    "Mastercharge Records",
    "Masters of Hardcore",
    "Masterworks Broadway Records",
    "Masterworks Records",
    "Matador Records",
    "Matty Grooves Records",
    "Mau5trap",
    "Maverick Records",
    "Mavin Records",
    "Maxi Records",
    "Maybach Music Group",
    "MCA Nashville Records",
    "MCA Records",
    "Medallion Records",
    "Mediarts Records",
    "Mediaskare Records",
    "Medium Productions",
    "Mega Records",
    "Megaforce Records",
    "Megalith Records",
    "Megarock Records",
    "Mego",
    "Mello Music Group",
    "Melodeon Records",
    "Melodic Revolution Records",
    "Melodiya",
    "Melotone Records (Australia)",
    "Melotone Records (US)",
    "Meltdown Records",
    "Memento Materia",
    "Memorandum Recordings",
    "Memory Lane Music Group",
    "Memphis Industries",
    "Menart Records",
    "Merciful Release",
    "Merck Records",
    "Mercury Nashville Records",
    "Mercury Records",
    "Merge Records",
    "Meridian Records",
    "Meritt Records (1925)",
    "Meritt Records (1979)",
    "Mermaid Records",
    "Merovingian Music",
    "Messenger Records",
    "Metal Blade Records",
    "Metal Heaven",
    "Metal Mind Productions",
    "Metalheadz",
    "Metascope Records",
    "Meteor Records",
    "MeteorCity Records",
    "Metro Records",
    "Metroplex",
    "Metropolis Records",
    "MFS",
    "MGM Distribution",
    "MGM Records",
    "MicBurnerz Music",
    "Microphone Records",
    "Midas Records Nashville",
    "Middle Pillar Presents",
    "Midhir Records",
    "Midijum Records",
    "Midland International Records",
    "Mighty Atom Records",
    "Mighty Force Records",
    "Mikroton Recordings",
    "Mind of a Genius",
    "Milan Entertainment",
    "Milan Records",
    "Milestone Records",
    "Mille Pattes Records",
    "Mille Plateaux",
    "Millennium Records",
    "Mimosa",
    "Minimal Wave",
    "Ministry of Sound",
    "Minit Records",
    "Minotauro Records",
    "Mint Records",
    "Minty Fresh",
    "Minus",
    "Miracle Revival Recordings",
    "Mirage Records",
    "Misfits Records",
    "Misra Records",
    "Missing Link Records",
    "Mission Records",
    "Mistletone",
    "Mixpak Records",
    "MLD Entertainment (formerly Duble Kick Company)",
    "Mnemosyne Productions",
    "Mo Thugs Records",
    "Mo-Da-Mu",
    "Mo' Hits Records",
    "Mo' Wax",
    "Mobile Fidelity Sound Lab",
    "Moda Records",
    "Mode Records",
    "Modern Outsider",
    "Modern Records",
    "Modular Recordings",
    "Moist Music",
    "Mojo Records",
    "Mokum Records",
    "Mom + Pop Music",
    "MonarC Entertainment",
    "Mondo Music Corporation",
    "Mondo Records",
    "Monika Enterprise",
    "Monitor Records (New York)",
    "Mono Vs Stereo",
    "Monstercat",
    "Montalban Hotel",
    "Montauk Mantis",
    "Montgomery Ward Records",
    "Monument Records",
    "Moon Ska Records",
    "Moon Ska World",
    "Mooncrest Records",
    "Moonfog Productions",
    "Moonglow Records",
    "Moonshine Music",
    "Mordam Records",
    "Morr Music",
    "Morrison Records (Australia)",
    "Morrison Records (Seattle)",
    "Mortarhate Records",
    "Mosaic Records",
    "Moseley Shoals Records",
    "Moshi Moshi Records",
    "Moshpit Tragedy Records",
    "Mosley Music Group",
    "Mötley Records",
    "Motor Music Records",
    "Motown Records",
    "Mountain Apple Company",
    "Mountain Fever Records",
    "Mountain Home Records",
    "Mountain Records",
    "Mouseville",
    "Move Records",
    "Moving Shadow",
    "Mr Bongo Records",
    "Mr. Lady Records",
    "Mt. Fuji Records",
    "MTM Records",
    "Mudhoney Records",
    "Mulatta Records",
    "Multiply Records",
    "Multitone Records",
    "Mums Records",
    "Murder Inc. Records",
    "Murderecords",
    "Murmur",
    "Musart Records",
    "Mush Records",
    "Mushroom Records (Australia)",
    "Mushroom Records (Canada)",
    "Music Brokers",
    "Music for Nations",
    "Music for Pleasure",
    "Music from the Corner",
    "Music Maker",
    "Music of Life",
    "Music West Records",
    "Music70",
    "Musica Omnia",
    "Musical Freedom",
    "MusicMasters Records",
    "Musicor Records",
    "Musicraft Records",
    "Musidisc",
    "Mutant League Records",
    "Mutant Pop Records",
    "Mute Records",
    "My Pal God Records",
    "Myrrh Records",
    "MySpace Records",
    "Mystic Records",
    "N-Coded Music",
    "n5MD",
    "Nacional Records",
    "Naked Records",
    "Napalm Records",
    "Nappy Boy",
    "Narada Productions",
    "Nardis Records",
    "Narita Records",
    "Narnack Records",
    "Nation Records",
    "National Music Lovers Records",
    "National Recording Corporation",
    "National Records",
    "Native Language Music",
    "Native Records",
    "Nature Sounds",
    "Naxos Records",
    "Naxos World",
    "Neat Records",
    "Necropolis Records",
    "Nega Network",
    "Neighborhood Records",
    "Nemesis Records",
    "NEMS Enterprises (Argentina)",
    "Neon Gold Records",
    "Neptune Records",
    "Nervous Records (U.K.)",
    "Nervous Records (U.S.)",
    "netMusicZone Records",
    "Nettwerk",
    "Network 23",
    "Network Records",
    "Neurot Recordings",
    "Neutral Records",
    "New Alliance Records",
    "New Amsterdam Records",
    "New Earth Records",
    "New European Recordings",
    "New Orleans Records",
    "New Red Archives",
    "New Renaissance Records",
    "New Wave Productions",
    "New West Records",
    "Newhouse Records",
    "Newmemorabilia Records",
    "Nexsound",
    "Next Plateau Records",
    "Nextera",
    "Ngoma",
    "Nick Records",
    "Nicola Delita",
    "Nicotine Records",
    "Niezależna Oficyna Wydawnicza CDN",
    "Nighthawk Records",
    "Nightshade Productions",
    "Nihilist Records",
    "Nilaihah Records",
    "Ninja Tune",
    "Nitro Records",
    "Nixa Records",
    "NMC Music",
    "No Fans Records",
    "No Fashion Records",
    "No Idea Records",
    "No Limit Records",
    "No Masters",
    "No Milk Records",
    "No Records",
    "No Remorse Records",
    "NoCopyrightSounds",
    "Noh Poetry Records",
    "Noise Records",
    "Noisebox Records",
    "Nonesuch Records",
    "NorCD",
    "Nordic Steel",
    "Nordskog Records",
    "Northern Records",
    "NorthernBlues Music",
    "NorthSide",
    "Northwestside Records",
    "Norton Records",
    "Not Lame Recordings",
    "Nothing Records",
    "Nova Zembla",
    "Novamute Records",
    "Now-Again Records",
    "NPG Records",
    "Ntone",
    "NTT Publishing Co.",
    "Nuclear Blast",
    "Nude Records",
    "Nukleuz",
    "Numa Records",
    "Nuphonic",
    "NUX Organization",
    "Oasis Records",
    "Obese Records",
    "Oblivion Records",
    "OBR Records",
    "Octone Records",
    "Odd Future Records",
    "Ode Records",
    "Odeon Records",
    "Off Beat",
    "Off Centaur Publications",
    "OFWGKTA",
    "Og Music",
    "Oglio Records",
    "Ogopa DJs",
    "Ogun Records",
    "Oh Boy Records",
    "Oi! the boat records",
    "Okeh Records",
    "Old Europa Cafe",
    "Old Homestead Records",
    "Oldies-33",
    "Oldies-45",
    "Oliver Sudden Productions",
    "Olivia Records",
    "Om Records",
    "Omnivore Recordings",
    "On-U Sound Records",
    "OnClassical",
    "Ondine",
    "One Big Spark",
    "One Eleven Records",
    "One Little Indian Records",
    "One Records (Scotland)",
    "One Records (Serbia)",
    "One Way Records",
    "Open Bar Entertainment",
    "Open Road Recordings",
    "Open Sky Records",
    "Open World Entertainment",
    "Opera Rara",
    "Operaphone Records",
    "OPUS Records",
    "Or Records",
    "Orange Record Label",
    "Orange Records",
    "Orange Twin Records",
    "Orchid Tapes",
    "ORFEO",
    "Orfeón",
    "Origin Jazz Library",
    "Original Blues Classics",
    "Original Jazz Classics",
    "Original Sound",
    "Oriole Records (U.S.)",
    "Oriole Records (UK)",
    "Orion Records",
    "Orpheus Music",
    "Osmose Productions",
    "Out of Line Music",
    "Outlaw Recordz",
    "Outpunk",
    "Output Recordings",
    "Outside Music",
    "Outta Sight Records",
    "Ovation Records",
    "Overcoat Recordings",
    "OVO Sound",
    "Ovum Recordings",
    "Owl Studios",
    "Owsla",
    "Oxford Records",
    "Oxide Entertainment",
    "OxRecs DIGITAL",
    "Oxygen Music Works",
    "P.S.F. Records",
    "P572",
    "Pablo Records",
    "Pacific Front Recordings",
    "Pacific Jazz Records",
    "Pagan Records",
    "Page One Records",
    "Paid In Full Entertainment",
    "Painted Smiles",
    "Paisley Park Records",
    "Palette Records",
    "Palm Pictures",
    "Palm Records",
    "Palo Duro Records",
    "PAN",
    "Panart Records",
    "Pandisc Records",
    "Pangea Recordings",
    "Panhellenion Records",
    "Panic Button Records",
    "Panorama Records",
    "Panton Records",
    "Panzerfaust Records",
    "Paper and Glue",
    "Paper Bag Records",
    "Papillion Records",
    "Paracadute",
    "Parachute Records",
    "Paramount Music",
    "Paramount Records",
    "Paramount Records (1969)",
    "Parasol Records",
    "Park Records",
    "Parkwood Entertainment",
    "Parlophone",
    "Parrot Records",
    "Parrot Records (Chicago)",
    "Partee Records",
    "Partisan Records",
    "Pasadena Records",
    "Pathé Records",
    "Patuxent Music",
    "Paw Tracks",
    "Peacefrog Records",
    "Peaceville Records",
    "Peacock Records",
    "Peak Records",
    "Peanuts & Corn Records",
    "Pearl Records",
    "Peerless Records",
    "Penalty Records",
    "Pendragon Records",
    "Pendu Sound Recordings",
    "Penny Farthing Records",
    "Pentatone",
    "People Mountain People Sea",
    "People Records",
    "Pepper Records",
    "Perfect Game Recording Co.",
    "Perfect Havoc",
    "Perfect Records",
    "Perfecto Records",
    "Perhaps Transparent",
    "Perishable Records",
    "Perlon",
    "Perspective Records",
    "Peter Pan Records",
    "PGP-RTB",
    "PGP-RTS",
    "Phantasy Sound",
    "Phantom City Studios",
    "Phantom Records",
    "Pharmacy Records",
    "Phase 4 Stereo",
    "Phi-Dan Records",
    "Phil Spector International",
    "Philadelphia International Records",
    "Philips Classics Records",
    "Philips Records",
    "Philles Records",
    "Phillips International Records",
    "Philly Groove Records",
    "Philo Records (folk)",
    "Philo Records (rhythm & blues)",
    "Phono-Cut Record Company",
    "Phonogenic Records (UK)",
    "Phonogram Records",
    "Photo Finish Records",
    "Pi Recordings",
    "PIAS Recordings",
    "Piccadilly Records",
    "Piccolo Town",
    "Pickled Egg Records",
    "Pickwick Records",
    "Piedmont Records",
    "Pigs Whisker Music",
    "Pilz",
    "Pina Records",
    "Pinecastle Records",
    "Pink and Black Records",
    "Pinnacle Records",
    "Pipeline Music",
    "Pivotal Rockordings",
    "Placid Casual",
    "Plainisphare",
    "Plaka Pilipino",
    "Plan 9 Records",
    "Plan-It-X Records",
    "Planet Dog",
    "Planet Mu",
    "Planet Pimp Records",
    "Plant Life Records",
    "Plantation Records",
    "Plasma Records",
    "Plastic Raygun",
    "Plastiq Musiq",
    "Platform Records",
    "Platina Records",
    "Platipus Records",
    "Play It Again Sam",
    "Play Me",
    "Playground Music Scandinavia",
    "Playhouse Records",
    "Playing With Sound",
    "Playmaker Music",
    "Playtone",
    "Pledis Entertainment",
    "Plopscotch Records",
    "Plug Research",
    "Plus 8",
    "Pluto Records",
    "Pneuma Recordings",
    "Poe Boy Entertainment",
    "POF Music",
    "Point Blank Records",
    "Point Music",
    "Poison Ivy",
    "Polar Music",
    "Polen Records",
    "Polo Grounds Music",
    "Polydor Records",
    "Polygon Records",
    "PolyGram",
    "Polymorph Records",
    "Polyvinyl Record Co.",
    "Pompeii Records",
    "Ponca Jazz Records",
    "Pony Canyon",
    "Popfrenzy",
    "Popsicle Records",
    "Poptones",
    "Pork Recordings",
    "Portrait Records",
    "Posh Boy Records",
    "Positiva Records",
    "Positive Tone",
    "Positron! Records",
    "Post Records",
    "Postcard Records",
    "Potential Getaway Driver",
    "Power It Up Records",
    "Power Out",
    "Pravda Records",
    "Prawn Song Records",
    "Precision Talent",
    "Prelude Records",
    "President Records",
    "Pressure Sounds",
    "Prestige Records",
    "Prikosnovénie",
    "Primary Music",
    "Priority Records",
    "Priory Records",
    "Prism Records",
    "Private Music",
    "Private Stock Records",
    "Pro Era Records",
    "Probe Plus",
    "Probe Records",
    "Procrastinate! Music Traitors",
    "Production House Records",
    "Profane Existence Records",
    "Profile Entertainment",
    "Profile Records",
    "Profile Records (Chief Records subsidiary)",
    "Profound Lore Records",
    "Project 3 Records",
    "Project Blowed",
    "Projekt Records",
    "Propeller Recordings",
    "Propeller Records",
    "Propeller Records (Boston)",
    "Prophecy Productions",
    "Prophet Entertainment",
    "Prosthetic Records",
    "Prosto Records",
    "Protest Records",
    "Provogue Records",
    "PRT Records",
    "PS Classics",
    "PSI Records",
    "Psy-Harmonics",
    "Psycho+Logical-Records",
    "Psychomania Records",
    "Psychonaut Records",
    "Psychopathic Records",
    "Psychout Records",
    "Punk Core Records",
    "Purchase Records",
    "Pure Steel Records",
    "Puritan Records",
    "Purple Feather Records",
    "Purple Music Switzerland",
    "Purple Records",
    "Purple Ribbon Records",
    "PUSA Inc.",
    "Putumayo World Music",
    "PWL",
    "Pye Golden Guinea Records",
    "Pye International Records",
    "Pye Records",
    "Pyramid Gang Records",
    "Q Division Records",
    "QN5 Music",
    "QRS Records",
    "Qualiton",
    "Qualiton Records",
    "Quality Control Music",
    "Quality Records",
    "Quango Music Group",
    "Quannum Projects",
    "Quarterstick Records",
    "Que-so Records",
    "Queen Bee Entertainment",
    "Queen Records",
    "Quiet Storm Records",
    "Quinlan Road",
    "Quirkworks Laboratory Discs",
    "Quote Unquote Records",
    "Qwest Records",
    "R.E.X. Records",
    "R.I.P Society Records",
    "R&S Records",
    "Rabid Records",
    "Racetrack Records",
    "Radar Records",
    "Radiant Future Records",
    "Radiex Records",
    "Radikal Records",
    "Radioactive Records",
    "Ragged Flag",
    "Ragnarock",
    "Rainbow Records",
    "Rainy Day Records",
    "Rajon Music Group",
    "Rak Records",
    "Ralph Records",
    "Ram Records (UK)",
    "Ram Records (US)",
    "Rama Records",
    "RandM Records",
    "Range Life Records",
    "Ranwood Records",
    "Rap-a-Lot Records",
    "Rappers Rapp Records",
    "RAS Records",
    "Raster-Noton",
    "Raucous Records",
    "Raven Records",
    "Ravenous Records",
    "Raw Energy Records",
    "Rawkus Records",
    "Razor & Tie",
    "Razzia Records",
    "RCA Camden",
    "RCA Records",
    "RCA Red Seal Records",
    "RCA Victrola",
    "RCA/Jive Label Group",
    "Re-Constriction Records",
    "Re-Up Records",
    "Reach Records",
    "React Music Limited",
    "Reaction Records",
    "Ready Records",
    "Real Talk Entertainment",
    "Real World Records",
    "Rebel Records",
    "Rebelle Records",
    "Rebelles Européens",
    "Recess Records",
    "Recommended Records",
    "Recorded In Hollywood",
    "Recorte Records",
    "RecRec Music",
    "Red Beet Records",
    "Red Bird Records",
    "Red Bull Records",
    "Red Eye Records",
    "Red Girl Records",
    "Red Hammer Records",
    "Red House Records",
    "Red Melon Records",
    "Red Pajamas Records",
    "Red Robin Records",
    "Red Rooster Records",
    "Red Star Records",
    "Redline Records",
    "RedOne Records",
    "Reel Life Productions",
    "Reflective Records",
    "Reflex Records",
    "Refuge Records",
    "Regain Records",
    "Regal Records (1914) (UK)",
    "Regal Records (1920) (Spain)",
    "Regal Records (1921) (US)",
    "Regal Records (1949) (US)",
    "Regal Zonophone Records",
    "Regency Records",
    "Regent Records (UK)",
    "Regent Records (US)",
    "Reinforced Records",
    "Rejoice Records",
    "Rekabet Records",
    "Rekords Rekords",
    "Relapse Records",
    "Relativity Records",
    "Releasing Eskimo",
    "Relentless Records",
    "Remedy Records (UK)",
    "Remedy Records (US)",
    "Remington Records",
    "Remington-Morse Records",
    "Remote Control Records",
    "Renaissance",
    "Renaissance Recordings",
    "Repeat Records",
    "Repertoire Records",
    "Rephlex Records",
    "Reprise Records",
    "Republic Records",
    "Resipiscent",
    "Resist Music",
    "Resistance Records",
    "Resonance Records",
    "Restless Records",
    "Rethink",
    "Retroactive Records",
    "Reunion Records",
    "Rev-Ola Records",
    "Reveal Records Label",
    "Revealed Recordings",
    "Revelation Records",
    "Revenant Records",
    "Reverb Records",
    "Reverberation",
    "Revival Records",
    "Revolution Records",
    "Rex Records (1912) (US)",
    "Rex Records (1933) (UK)",
    "Rex Records (1957) (US)",
    "Rex Records (2001) (UK)",
    "RFUGrey",
    "Rhino Entertainment",
    "Rhymesayers Entertainment",
    "Rhythm King",
    "Rhythm Zone",
    "Rice Music",
    "Rich Forever Music",
    "Ridge Runner Records",
    "Righteous Babe Records",
    "Rikos Records",
    "Riot City Records",
    "Ripete Records",
    "Ripple Music",
    "Rise Above Records",
    "Rise Records",
    "Rising Tide Records",
    "Riva Records",
    "River Jones Music",
    "Riverside Records",
    "RKO/Unique Records",
    "remarQabl",
    "RMM Records & Video",
    "Roadrunner Records",
    "Rob's House Records",
    "Rob's Records",
    "Robbins Entertainment",
    "Robot Needs Home",
    "Robotic Empire",
    "Roc Nation",
    "Roc-A-Fella Records",
    "Roc-La-Familia",
    "Rock 'n Roll Records",
    "Rock Action Records",
    "Rock Bottom Entertainment",
    "Rock Records",
    "Rock Ridge Music",
    "Rock-O-Rama",
    "Rocket Girl",
    "Rocket Records",
    "Rocketown Records",
    "Rockland Records",
    "Rodven Records",
    "Rogue Music Alliance",
    "ROIR",
    "Rolling Stones Records",
    "Romeo Records",
    "Romophone",
    "Ron Johnson Records",
    "rooArt",
    "Roost Records",
    "Rooster Blues",
    "Ropeadope Records",
    "Rostrum Records",
    "Roswell",
    "Rotana",
    "Rotorelief",
    "Rotters Golf Club",
    "Rough Trade Records",
    "Roulé",
    "Roulette Records",
    "Rounder Records",
    "Route 66 Records",
    "Rowdy Records",
    "Roxy Recordings",
    "Royal Empire Records",
    "Royalty Records",
    "RPM Records (United Kingdom)",
    "RPM Records (United States)",
    "RRRecords",
    "RS Music",
    "RSO Records",
    "Rubber Jungle Records",
    "Ruby Records",
    "Rude Records",
    "Ruf Records",
    "Ruff Ryders Entertainment",
    "Ruffhouse Records",
    "RuffNation Records",
    "Run For Cover Records",
    "Rune Grammofon",
    "Rural Rhythm Records",
    "Rushmore Records",
    "Russell Simmons Music Group",
    "Rust Nashville",
    "Rust Records, NY",
    "Ruthless Records",
    "Ruthless Records (Chicago)",
    "Rykodisc",
    "S-Curve Records",
    "S. Carter Records",
    "S.M. Entertainment",
    "S2 Records",
    "S2S Pte. Ltd.",
    "Sabre Records",
    "Sacred Bones Records",
    "Sacred Records",
    "Saddle Creek Records",
    "Safehouse Records",
    "Saga Records",
    "Sähkö Recordings",
    "Sain",
    "Saja Records",
    "Salsoul Records",
    "Salted Music",
    "Samadhi Sound",
    "San Francisco Records",
    "Sanctuary Records",
    "Sandy Records",
    "Sappy Records",
    "SAR Records",
    "Sarah Records",
    "Sarathan Records",
    "Saravah",
    "Saregama",
    "Sargent House",
    "Satellite Records",
    "Sartorial Records",
    "Savannah Records",
    "Savoy Records",
    "Savvy Records",
    "Saw Recordings",
    "SBK Records",
    "Scantraxx Recordz",
    "Scared Records",
    "Scarlet Records",
    "Scat Records",
    "Scepter Records",
    "Schematic Records",
    "Schirmer Records",
    "Schism Records",
    "School Boy Records",
    "SCI Fidelity Records",
    "Science Friction",
    "Scion Audio/Visual",
    "Scitron",
    "Scotti Brothers Records",
    "Scratchie Records",
    "Screwgun Records",
    "Season of Mist",
    "Seclusiasis",
    "Secret City Records",
    "Secret Records",
    "Secretly Canadian",
    "Sedimental",
    "See For Miles Records",
    "See Thru Broadcasting",
    "Seed Records",
    "Seeland Records",
    "Select Records",
    "Selectric Records",
    "Selegna Records",
    "Self Immolation",
    "Selfmade Records",
    "Sequel Records",
    "Seraphim Records",
    "Serio Controla Records",
    "Serious Business Records",
    "Serjical Strike Records",
    "Service",
    "Setanta Records",
    "Seventh Rule Recordings",
    "Several Reasons",
    "Severn Records",
    "Sh-K-Boom Records",
    "Shadow Records",
    "Shady Records",
    "Shanachie Records",
    "Sheeba Records",
    "Sheer Sound",
    "Shelflife Records",
    "Shelter Records",
    "Shifty Records (South Africa)",
    "Shifty Records (USA)",
    "Shimmy Disc",
    "Shinkansen Records",
    "Shitkatapult",
    "Sho'nuff Records",
    "Shock Records",
    "Shogun Audio",
    "Shout Records",
    "Show Dog Nashville",
    "Shrapnel Records",
    "Shrimper Records",
    "Sic Squared Records",
    "Sick Room Records",
    "Side One Recordings",
    "SideCho Records",
    "SideOneDummy Records",
    "Sidewalk Records",
    "Sierra Records",
    "Sigma Editions",
    "Signature Records",
    "Signature Sounds Recordings",
    "Silas Records",
    "Silence Records",
    "Silkheart Records",
    "Siltbreeze",
    "Silver Planet",
    "Silvertone Records (1905) (US)",
    "Silvertone Records (1980) (UK)",
    "Silvertone Records (Selfridges) (UK)",
    "SimG Records",
    "Simian Records",
    "Sims Records",
    "Sinnbus",
    "Sire London Records",
    "Sire Records",
    "Sister Benten Online",
    "Situation Two",
    "Six Degrees Records",
    "Six Shooter Records",
    "Sixsevenine",
    "sixstepsrecords",
    "Ska Satellite Records",
    "Skam Records",
    "Skeleton Crew",
    "Skin Graft Records",
    "Skint Records",
    "Skirl Records",
    "Skunk Records",
    "Sky and Trees Records",
    "Sky Records",
    "Skybucket Records",
    "Skylite",
    "Slam Dunk Records",
    "Slampt",
    "Slash Records",
    "Slate Creek Records",
    "Slave Pit Records",
    "Sledgehammer Blues",
    "Sleep It Off Records",
    "Sleeping Bag Records",
    "Slip-N-Slide Records",
    "Slowdime Records",
    "Small Stone Records",
    "Small Wonder Records",
    "Smalltown America",
    "Smalltown Superjazzz",
    "Smalltown Supersound",
    "Smash Records",
    "Smash The House",
    "Smells Like Records",
    "Smithsonian Folkways Recordings",
    "Smoke-A-Lot Records",
    "Snail Records",
    "Snakes & Ladders Records",
    "Snapper Music",
    "Sniper Records",
    "SNOtone",
    "So So Def Recordings",
    "SoBe Entertainment",
    "Socialist Roots Sound System",
    "Softdrive Records",
    "SOLAR Records",
    "Soleilmoon",
    "Solid Rock Records",
    "Solid State Records (Christian metal)",
    "Solid State Records (jazz)",
    "Solitary Man Records",
    "Soma Quality Recordings",
    "Soma Records",
    "Some Bizzare Records",
    "SonaBLAST! Records",
    "Sonar Kollektiv",
    "Song Bird Records",
    "Sonic Past Music",
    "Sonic Unyon Recording Company",
    "Sonic Wave America",
    "Sonic Youth Recordings",
    "Sonic360 Records",
    "Sons Ltd",
    "Sony BMG",
    "Sony BMG Masterworks",
    "Sony Classical Records",
    "Sony Music Entertainment",
    "Sony Music Entertainment Japan",
    "Sony Music India",
    "Sony Music Special Products",
    "Sony Records",
    "Sony Wonder",
    "Sony/ATV Music Publishing",
    "Sordide Sentimental",
    "Soul City Records",
    "Soul Jazz Records",
    "Soulfuric Recordings",
    "Soulseek Records",
    "Soulection",
    "Sound Circus",
    "Sound Document",
    "Sound of Gospel",
    "Sound Riot Records",
    "Sound Stage 7",
    "Sound System Records",
    "Sounds Are Active",
    "Soundway Records",
    "South Indies",
    "Southern Empire Records",
    "Southern Fried Records",
    "Southern Lord Records",
    "Southern Records",
    "Southern Studios",
    "Southland Records",
    "Spaghetti Records",
    "Spalax",
    "Spare Me Records",
    "Spark Records",
    "Sparrow Records",
    "Sparrowheart Music",
    "Spartan Records",
    "Specialty Records",
    "Spectral Sound",
    "Speed Label",
    "Spiderleg Records",
    "Spin Records (Australia)",
    "Spin Records (US)",
    "Spinalonga Records",
    "spinART Records",
    "Spinefarm Records",
    "Spinnin' Records",
    "Spinnup",
    "Spirit Zone Records",
    "Spit Shine Records",
    "Spitfire Records",
    "Spivey Records",
    "Spoon Records",
    "Spotlite Records",
    "Spring Hill Music Group",
    "Springman Records",
    "SPV GmbH",
    "Squint Entertainment",
    "SRC Records",
    "SST Records",
    "St. George Records",
    "Staalplaat",
    "Stacks on Deck Entertainment",
    "Stage Door Records",
    "Stamps-Baxter Music Company",
    "Stand Up! Records",
    "Standard Deviation",
    "Standard Talking Machine Company",
    "StandBy Records",
    "Star Music (Philippines)",
    "Star Recordings",
    "Star Song Communications",
    "Star Trak Entertainment",
    "Starboy Entertainment",
    "Starday Records",
    "Stardust Promotion",
    "Starkland",
    "Starr Records",
    "Starship Entertainment",
    "Starstream Records",
    "Startime International",
    "States Records",
    "Stateside Records",
    "Static Caravan Recordings",
    "Stax Records",
    "STC Recordings",
    "Stealth Records",
    "Steed Records",
    "Steel Tiger Records",
    "Steelpride Records",
    "Steeltown Records",
    "Steelwork Maschine",
    "Step One Records",
    "Stereolaffs",
    "Sterile Records",
    "Sterling Records (Sweden)",
    "Sterling Records (US)",
    "Sterno Records",
    "Stickfigure Records",
    "Stidham Records",
    "Stiff Records",
    "Stigmata",
    "Stillborn Records",
    "Stmpd Rcrds",
    "Stockfisch Records",
    "Stockholm Records",
    "Stolen Recordings",
    "Stomp Records",
    "Stone Records",
    "Stone'd Records",
    "Stones Throw Records",
    "Stony Plain Records",
    "Storch Music Company",
    "Storyville Records",
    "Storyville Records (George Wein's)",
    "Straight Records",
    "Strange Famous Records",
    "Strange Fruit Records",
    "Strange Music",
    "Strange Ways",
    "Strata Music",
    "Strata-East Records",
    "Street Records Corporation",
    "StreetSounds",
    "Streetsweepers Entertainment",
    "Strictly Rhythm",
    "Studio !K7",
    "Studio One",
    "Studioseven Recordings",
    "Stunt Records",
    "Stupak Records",
    "Suave House Records",
    "Sub City Records",
    "Sub Pop",
    "Sub Rosa",
    "Sub Verse Records",
    "Sub•Lime Records",
    "Subconscious Communications",
    "Sublime Frequencies",
    "Subliminal",
    "Submerged Records",
    "Subplate Records",
    "Subterranean Records",
    "Suburban Base",
    "Suburban Home Records",
    "Suburban Noize Records",
    "Suburban Records",
    "Suburban Sprawl Music",
    "Such-A-Punch Media",
    "Suckapunch Records",
    "Sudden Death Records",
    "Sue Records",
    "Sugar Hill Records (bluegrass)",
    "Sugar Hill Records (rap)",
    "Suleputer",
    "Sumerian Records",
    "Summersteps Records",
    "Summit Records",
    "Sumthing Distribution",
    "Sun Records",
    "Suncity Records",
    "Sundazed Records",
    "Sunflower Records",
    "Sunset Alliance",
    "Sunset Records",
    "Sunshine Records (Australia)",
    "Sunshine Records (USA)",
    "Suntrip Records",
    "Suomen Musiikki",
    "Super Records",
    "Supernal Music",
    "Supersoul Records",
    "Supertone Records",
    "Supraphon",
    "Supreme Records (Grey Gull subsidiary)",
    "Supreme Records (Los Angeles)",
    "Supreme Records (Pama subsidiary)",
    "Supreme Records UK",
    "Surfdog Records",
    "Surprise Attack Records",
    "Surprise Records",
    "Survivor Records",
    "Sussex Records",
    "Sutemos",
    "Sutton Records",
    "Suzy",
    "SVR Producciones",
    "Swallow Records",
    "Swami Records",
    "Swan Records (jazz label)",
    "Swan Records (Philadelphia, PA)",
    "Swan Song Records",
    "Sweet Lucy Records",
    "Swing Time Records",
    "Swishahouse",
    "Sword Records",
    "Syco Music",
    "Sympathy for the Record Industry",
    "Synthetic Sounds",
    "T-Neck Records",
    "T-Series",
    "T.A. Music",
    "Taang! Records",
    "Table of the Elements",
    "Tabu Recordings",
    "Tabu Records",
    "TAG Recordings",
    "Take Fo' Records",
    "Takeover Records",
    "Takoma Records",
    "Talitres",
    "Talkin' Loud",
    "Tall Poppies Records",
    "Talos Records",
    "Tamla Records",
    "Tan Cressida",
    "Tangerine Records (1963) (US)",
    "Tangerine Records (1992) (UK)",
    "Tankcrimes",
    "TANZA (New Zealand)",
    "Tapete Records",
    "Tara Music label",
    "Tarantulas Records",
    "Tarantura",
    "Taste Media",
    "Taylor Gang Records",
    "Tayster and Rojac Records",
    "TCR",
    "Tea Pot Records",
    "Team Love",
    "Tee Pee Records",
    "Tee Productions",
    "Tee Records",
    "Teem Records",
    "Teenage USA Recordings",
    "TeenBeat Records",
    "Teichiku Records",
    "Telarc International Corporation",
    "Teldec Record Service",
    "Teleprompt Records",
    "Tellus Audio Cassette Magazine",
    "Telstar Records",
    "Tempa",
    "Temple Records (1978 UK label)",
    "Temple US Records",
    "Tempo Records (UK)",
    "Tempo Records (US)",
    "Temporary Music",
    "Temporary Residence Limited",
    "TEN Music Group",
    "Ten12 Records",
    "Tender Loving Empire",
    "Tennessee Records",
    "Tent Show Records",
    "Terror Squad",
    "Tesco Organisation",
    "Test Tube Records",
    "Testament Records (UK)",
    "Testament Records (USA)",
    "Tetragrammaton Records",
    "Tetrapod Spools",
    "Texas Hotel Records",
    "The Amazing Kornyfone Record Label",
    "The Bedtime Record",
    "The Black Wall Street Records",
    "The C Kunspyruhzy",
    "The Control Group",
    "The Elephant 6 Recording Company",
    "The End Records",
    "The Flenser",
    "The Ford Plant",
    "The Goldmind Inc.",
    "The Groove Thing",
    "The Inc. Records",
    "The Island Def Jam Music Group",
    "The Leaf Label",
    "The Meny-X",
    "The Militia Group",
    "The Moonshine Conspiracy",
    "The Music Cartel",
    "The Noise Company",
    "The Orchard",
    "The Post War Blues",
    "The Reverberation Appreciation Society",
    "The Rocket Record Company",
    "The Social Registry",
    "The Ultimate Group",
    "The Unilalia Group",
    "The Viper Label",
    "The Winner Records",
    "Thee Sheffield Phonographic Corporation",
    "Thick Records",
    "Thick Syrup Records",
    "Think Music Records",
    "Thinner",
    "Third Man Records",
    "Third Mind Records",
    "Thirsty Ear Recordings",
    "This Is American Music",
    "Thizz Entertainment",
    "Three Gut Records",
    "Three One G",
    "Threshold House",
    "Threshold Records",
    "Thrill Jockey",
    "Thug Line Records",
    "Thump Records",
    "Tiara Records",
    "Tico Records",
    "Tiger Lily Records",
    "Tiger Style Records",
    "Tigerbeat6",
    "Tigersushi Records",
    "Tigertrap Records",
    "Timberyard Records",
    "Time Bomb Recordings",
    "Time Records",
    "Time-Lag Records",
    "Time–Life",
    "Tino Corp.",
    "Tiny Dog Records",
    "Tiny Evil Records",
    "Tirk Records",
    "Titanic Records",
    "TK Records",
    "To the Fallen Records",
    "Toast Hawaii",
    "Todamerica Records",
    "Toes in the Sand Recordings",
    "Tofu Records",
    "Tokuma Shoten",
    "Tokyo Dawn Records",
    "Tollie Records",
    "Tomato Head Records",
    "Tombstone Records",
    "Tomlab",
    "Tommy Boy Records",
    "Tompkins Square Records",
    "Too Pure",
    "Tooth & Nail Records",
    "Top Dawg Entertainment",
    "Top Rank Records",
    "Topic Records",
    "TopTen",
    "Toshiba-EMI",
    "Tôt ou tard",
    "Touch and Go Records",
    "Touch Records",
    "Tower Records",
    "Tr1be Records",
    "Track Records",
    "Trademark of Quality",
    "Tradisom",
    "Tradition Records",
    "Trance Syndicate",
    "Trans Continental Records",
    "Transatlantic Records",
    "Transcopic Records",
    "Transgressive Records",
    "Transmat",
    "Transmission Communications",
    "Trash Aesthetics",
    "Trash Art!",
    "Trattoria Records",
    "Traum Schallplatten",
    "Trauma Records",
    "Trax Records",
    "Trekky Records",
    "trend is dead! records",
    "Trend Records",
    "Trensmat Records",
    "Trente Oiseaux",
    "Tres Records",
    "Tresor",
    "Trial & Error Records",
    "Tribe Records",
    "Tribe Records (NO)",
    "Tribute Records",
    "Trickdisc Recordings",
    "Trifekta",
    "Trikont",
    "Trill Entertainment",
    "Triple Crown Records",
    "Trisol Music Group",
    "Triumph Records (UK)",
    "Triumph Records (US)",
    "Trix Records",
    "Trojan Records",
    "Troubleman Unlimited Records",
    "Trout Music",
    "Tru 'Dat' Entertainment",
    "Tru Thoughts",
    "Truck Records",
    "True North Records",
    "True Panther Sounds",
    "True Tone Records",
    "Trumpet Records",
    "Trumpet Swan Productions",
    "Trunk Records",
    "Trust in Trance Records",
    "Trustkill Records",
    "Try Me Records",
    "TSOP Records",
    "TSR Records",
    "Tuff City Records",
    "Tuff Gong",
    "Tug Records",
    "Tumi Music",
    "Tumult Records",
    "Tunnel Records",
    "Tutl",
    "TVT Records",
    "Twin/Tone Records",
    "Twisted Nerve Records",
    "Twisted Records (UK)",
    "Twisted Records (US)",
    "Tyscot Records",
    "Tzadik Records",
    "Ubiquity Records",
    "Ugly Man Records",
    "Ugly Nephew Records",
    "UHD&C Record Company",
    "Ujikaji",
    "UK Records",
    "Ultimae Records",
    "Ultra Records",
    "Umami Records",
    "UMTV",
    "Unable Records",
    "Unart Records",
    "Uncensored Records",
    "Uncle Howie Records",
    "Unda K9 Records",
    "Under One Flag",
    "Undercover Prodigy",
    "Undergroove Records",
    "Underground Operations",
    "Underground Resistance",
    "UNESCO Collection",
    "UNFD",
    "Unholy Records",
    "Uni Records",
    "Unicorn Digital",
    "Unicorn-Kanchana",
    "Unidisc Music Inc.",
    "Union City Recordings",
    "Union Label Group",
    "United Artists Records",
    "United Masters",
    "United Records",
    "United Records (1910s)",
    "United Telefilm Records",
    "Universal Classics Group",
    "Universal Edition",
    "Universal Motown Records Group",
    "Universal Music Group",
    "Universal Music India",
    "Universal Music Latin Entertainment",
    "Universal Records",
    "Universal South Records",
    "University Recording Company",
    "Univision Music Group",
    "Unlockedgroove",
    "Unstable Ape Records",
    "Up Above Records",
    "Up Records",
    "UpFront Records",
    "Uppity Cracker",
    "Uprising Records",
    "Upsetter Records (Jamaica)",
    "Upsetter Records (USA)",
    "Upside Records",
    "Uptown Records",
    "Urban Jungle",
    "Urban Records",
    "URBNET Records",
    "Urgent! Records",
    "Urinine Records",
    "US Records",
    "Utech Records",
    "Utopia Records",
    "UTP Records",
    "UZI Suicide",
    "V Recordings",
    "V-Disc",
    "V.I.P. Records",
    "V.I.S.A.",
    "V/Vm Test Records",
    "V2 Records",
    "Vagrant Records",
    "Valcour Records",
    "Valiant Records",
    "Valley Entertainment",
    "Valley Records",
    "Valve Records",
    "Van Dyke Records",
    "Vandit",
    "Vanguard Records",
    "VAP (Video & Audio Project)",
    "Varèse-Sarabande Records",
    "VAWS (Verlag und Agentur Werner Symanek)",
    "VDE-Gallo Records",
    "VEB Deutsche Schallplatten",
    "Vee-Jay Records",
    "Velour Recordings",
    "Velvet Blue Music",
    "Velvet Tone Records",
    "Vena Records",
    "Vendetta Records",
    "Vendlus Records",
    "Verity Records",
    "Vermin Scum",
    "Vertigo Records",
    "Verve Records",
    "Very GOOD Beats",
    "Very Small Records",
    "VI Music",
    "Vicious Vinyl",
    "Victoria Records (1952)",
    "Victory Records",
    "Vik Records",
    "ViK. Recordings",
    "Villain Entertainment",
    "Villar Records",
    "Vim Records",
    "Vinyl Solution",
    "Virgin Records",
    "Virgin Schallplatten",
    "Virt Records",
    "Visible Noise",
    "Vision Quest Records",
    "Visionary Music Group",
    "Vitamin Records",
    "Viva Records",
    "Viva Records (Philippines)",
    "Vocalion Records",
    "Vogue Records",
    "Voiceprint Records",
    "Voices of Wonder",
    "Volcano Entertainment",
    "Volcom Entertainment",
    "Volition Records",
    "Volt Records",
    "Vox Records",
    "Vox Records (Germany)",
    "VP Records",
    "Wackies",
    "Waerloga Records",
    "Wagram Music",
    "Waldorf Music Hall Records",
    "Wall of Sound",
    "Wallis Original Records",
    "Walt Disney Records",
    "Wand Records",
    "Wanker Records",
    "Warcon Enterprises",
    "Warm Fuzz Records",
    "Warm Records",
    "Warner Alliance",
    "Warner Curb Records",
    "Warner Music Australasia",
    "Warner Music Canada",
    "Warner Music Group",
    "Warner Records",
    "Warner-Spector Records",
    "Warp Records",
    "Warwick Records (United Kingdom)",
    "Warwick Records (United States)",
    "Water Lily Acoustics",
    "Waterbug Records",
    "Waterfront Records",
    "Watermelon Records",
    "WaterTower Music",
    "WAU! Mr. Modo Recordings",
    "Wave Music",
    "Waveform Records",
    "Wax Trax! Records",
    "Waxploitation Records",
    "We Put Out Records",
    "WE R Music",
    "We The Best Music",
    "Weathermaker Music",
    "Web Entertainment",
    "Webco Records",
    "Wedge Records",
    "WEDIDIT",
    "Weed Records",
    "Weewerk",
    "Welk Music Group",
    "WERGO",
    "Werk Discs",
    "West Craft Records",
    "West Records",
    "Westbound Records",
    "Westminster Records",
    "Westpark Music",
    "Westport Records",
    "Westside Records",
    "WGNS Recordings",
    "What Have You Records",
    "What Records",
    "What? Records",
    "Whirlin' Disc Records",
    "White Noise Records",
    "White Pine Music",
    "White Whale Records",
    "Whitehaus Family Record",
    "Whitfield Records",
    "Why Not Records",
    "Wichita Recordings",
    "Wiiija",
    "Wilbury Records",
    "Wild Pitch Records",
    "Wild Rags",
    "Wildside Records",
    "Wildstar Records",
    "Willowtip Records",
    "Wind Music",
    "Wind-Up Records",
    "Windfall Records",
    "Windham Hill Records",
    "Wing Records",
    "Winley Records",
    "Winter & Winter",
    "Witches Brew",
    "WM Recordings",
    "WMOT Records",
    "WOLV Records",
    "Wooden Nickel Records",
    "Woodrich Records",
    "Woodsist",
    "Woodworm Records",
    "Woof Records",
    "Woollim Entertainment",
    "Word Records",
    "Work Records",
    "Workerbee Records",
    "Workshop Jazz Records",
    "World Circuit",
    "World Domination Recordings",
    "World Record Club",
    "World Serpent Distribution",
    "Wounded Bird Records",
    "WOW Music",
    "Wrasse Records",
    "Wrath Records",
    "WTII Records",
    "Wu-Tang Records",
    "WWE Records",
    "WY Records",
    "Wyncote Records",
    "Wynona Records",
    "Xanadu Records",
    "Xemu Records",
    "Xenophile Records",
    "XIII Bis Records",
    "XL Recordings",
    "XO Records",
    "Xpressway",
    "XRECORDS (record Label) distributed by Malaco",
    "Xtra Mile Recordings",
    "Xtreem Music",
    "XYZ Records",
    "Y Records",
    "Yambo Records",
    "Yazoo Records",
    "YB Music",
    "YBNL Nation",
    "YEAR0001",
    "Yellow Tail Records",
    "Yellow Van Records",
    "Yep Roc Records",
    "Yer Bird Records",
    "YG Entertainment",
    "Yoshitoshi Records",
    "Young God Records",
    "Young Money Entertainment",
    "Young Turks",
    "YSL Records",
    "Yuletide Records",
    "Zarape Records",
    "Zarjazz",
    "ZE Records",
    "Zebra Records",
    "Zee Music Company",
    "Zell's Records",
    "Zéro Musique",
    "Zero Records",
    "Zomba Recording",
    "Zone 4",
    "Zonophone Records",
    "Zoo Entertainment",
    "Zoom Lens",
    "Zoth Ommog Records",
    "ZTT Records",
    "Zunior",
    "ZYX Music",
    "Other"
]

export const Distributor = [
    "Absolute",
    "Alternative Distribution Alliance",
    "Amuse",
    "AvidPlay",
    "Awal",
    "Bandcamp",
    "Beatchain",
    "Believe Digital",
    "Boost Collective",
    "Caroline International",
    "CDbaby",
    "Consolidated Independent",
    "DistroKid",
    "Ditto music",
    "FreeMusicDistribution.com",
    "Fresh Tunes",
    "Fuga",
    "Horus Music",
    "Idol",
    "iMusician Digital",
    "INgrooves",
    "Kudos Distribution",
    "Label Engine",
    "Label Worx",
    "LabelGrid",
    "Landr",
    "Level Music",
    "Listn",
    "Mondo Tunes",
    "Music Gateway",
    "Music Info",
    "MusicDiffusion",
    "Octiive",
    "OneRPM",
    "Record Union",
    "Redeye Worldwide",
    "Repost Network",
    "Reverbnation",
    "Routenote",
    "Songcast",
    "SongCast",
    "SongTradr",
    "Sonomo",
    "Sonosuite",
    "Soundrop",
    "Soundtrap",
    "SpareMusic",
    "Spinnup Music",
    "Stem Music",
    "Symphonic",
    "The Orchard",
    "Triple Vision",
    "TuneCore",
    "United Masters",
    "Other"
]